<template>
  <div class="relative" style="height: auto;">
    <AppHeader />
    <router-view></router-view>
    <div
      v-if="$route.path === '/'"
      class="header-container flex flex-col items-center bg-cover bg-center relative"
      :style="`background-image: url(${headerImageUrl})`"
    >
    <div class="overlay absolute inset-0 bg-blue-900 opacity-90"></div>      
      <div class="flex flex-col items-center z-10" :class="{ 'hidden': showQuiz || showContact }">
        <div class="key-points-container text-left sm:text-center pt-0 sm:pt-20 md:pt-10 px-2 sm:px-0">
          <h1 class="text-xl md:text-3xl font-extrabold text-white text-center pt-7 sm:text-center px-2">We Buy Property for Cash, Fast in Any Condition, with 100% No Fees!</h1>


          <ul class="mt-4 flex space-x-0 sm:space-x-4 md:space-x-6 justify-start sm:justify-center flex-wrap text-left text-white">
            <li class="flex items-center mb-2 px-2 sm:px-0 pr-6">
              <i class="fas fa-check text-green-500 mr-2"></i> Quick sale process
            </li>
            <li class="flex items-center mb-2 px-2 sm:px-0 pr-6">
              <i class="fas fa-check text-green-500 mr-2"></i> No need for viewings
            </li>
            <li class="flex items-center mb-2 px-2 sm:px-0 pr-6">
              <i class="fas fa-check text-green-500 mr-2"></i> Sell in 7-21 days
            </li>
            <li class="flex items-center mb-2 px-2 sm:px-0 pr-6">
              <i class="fas fa-check text-green-500 mr-2"></i> Flexible moving dates
            </li>
            <li class="flex items-center mb-2 px-2 sm:px-0 pr-6">
              <i class="fas fa-check text-green-500 mr-2"></i> No last-minute price drops
            </li>
          </ul>
          
        </div>
        <AddressForm @addressSelected="onAddressSelected" class="w-full px-4 sm:w-1/2 sm:px-0 mt-0" />

<div class="mt-4 small-device-text">
  <div class="w-full sm:max-w-sm mx-auto text-sm text-gray-300 pb-5">
    <p class="text-xs font-bold mb-2">
  <span class="text-red-500 mr-1">&#9888;</span>
  There is no obligation to accept our offer. We understand that each property has unique circumstances. Our team thoroughly evaluates every property based on its individual merit.
</p>
          </div>
        </div>
      </div>

      <div class="flex flex-col items-center pt-10 mx-4 justify-start z-10" :class="{ 'hidden': !showQuiz }">
        <div class="text-3xl font-bold text-white mb-8">
          Quick Property Evaluation
        </div>

        <QuizForm @completed="onQuizComplete" />
        <div class="sm:max-w-sm mx-auto text-sm text-white pt-5 pb-10" style="font-size: 12px;">
          Please note, this is a preliminary evaluation to determine your property's eligibility for a cash offer from us, not a comprehensive online valuation. We appreciate your understanding.
        </div>
      </div>

      <div class="flex flex-col items-center pt-10 justify-start z-10 pb-10" :class="{ 'hidden': !showContact }">
        <ContactForm :address-data="addressData" 
                      :quiz-data="quizData" 
                      :gclid="gclid" 
                      :utmCampaign="utmCampaign" 
                      :utmSource="utmSource" 
                      :utmMedium="utmMedium"
                      :utmKeyword="utmKeyword"
                      :utmMatch="utmMatch" 
                      :utmDevice="utmDevice" />
      </div>
    </div>

    <div v-if="$route.path === '/contact-us' || $route.path === '/' || $route.path === '/how-we-work' || $route.path === '/about-us'" class="countdown-container py-4 bg-gray-100">
        <countdown-timer />
    </div>
  </div>

  <div v-if="!$route.path.startsWith('/articles/') && !$route.path.startsWith('/landerV1/') && !$route.path.startsWith('/services/') && !$route.path.startsWith('/landerV2/') && !$route.path.startsWith('/thank-you') && !$route.path.startsWith('/location')" class="guaranteed-offer">
  <h2 class="font-bold mb-8 text-center" style="font-size: 1.5rem;">Want a Guaranteed Cash Offer for your Property?</h2>
      <p class="px-4 mb-4">
        With no fees, complete flexibility on your moving date, 100% confidentiality, no stress and no estate agents! We provide speed, certainty, simplicity.
      </p>
      <div class="offer-steps">
        <div class="step">
          <i class="fas fa-comments chat-bubble-icon"></i>
          <p>Tell us about your property</p>
        </div>
        <div class="step">
          <i class="fas fa-handshake cash-offer-icon"></i>
          <p>Accept our cash offer</p>
        </div>
        <div class="step">
          <i class="fas fa-university bank-icon"></i>
          <p>Cash in the bank in 7 days</p>
        </div>
      </div>
      <p class="px-4 mb-4">
        Our service is simple, stress-free, giving your peace of mind.
      </p>
      <button class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded shadow-lg mt-6 text-xl mx-auto block" @click="handleButtonClick">
      Get a Free Cash Offer <i class="fas fa-arrow-right ml-2"></i>
    </button>
    </div>

    <div v-if="!($route.path.startsWith('/landerV1/') || $route.path.startsWith('/landerV2/')  || $route.path.startsWith('/services/') || $route.path.startsWith('/articles/') || $route.path.startsWith('/thank-you') || $route.path.startsWith('/location'))" class="bg-blue-800 full-width flex justify-center items-center text-white p-4">
    <div class="bg-blue-50 p-6 rounded-lg shadow-lg max-w-6xl mx-auto text-gray-800">
      <h2 class="text-2xl font-semibold mb-4">Why Choose ASAP House Buyers to Sell Your House Fast?</h2>
        
      <p class="mb-4">Are you on the quest for a <strong>reliable home-buying company</strong>, navigating through endless lists of <strong>companies that buy houses for cash</strong>? Finding the <strong>fastest way to sell</strong> your house can seem daunting.</p>

<p class="mb-4">Seeking not just any offer, but a fair one, without the complexities and delays of the <strong>open market</strong>? Prefer a process that's more straightforward than dealing with <strong>traditional estate agents</strong> or the uncertainty of <strong>online estate agents</strong>?</p>

<p class="mb-4">Your search ends with <strong>ASAP House Buyers</strong>. Unlike a <strong>part exchange</strong> or waiting on <strong>property buyers</strong> through <strong>local estate agents</strong>, we make an offer to sell your house quickly, efficiently, and without hidden fees.</p>

<p class="mb-4">The journey of selling your property can be fraught with unpredictability when you're aiming for the <strong>full market</strong> value through <strong>high street estate agents</strong>. Opting to <strong>sell your property to us</strong> means you bypass these hurdles, securing a <strong>quick property sale</strong> within just 7 - 21 days.</p>

<p class="mb-4">We're transparent about our process: our offer will be <strong>below market value</strong>, but in return, you avoid the fees, the waiting, and the stress typically associated with the sale. As a recognised <strong>quick house sale company</strong>, we guarantee a smooth transaction.</p>

<p class="mb-6">As dedicated <strong>cash buyers</strong>, ASAP House Buyers offers you peace of mind and a <strong>guaranteed house sale</strong>, steering clear of the pitfalls of selling <strong>property on the open market</strong>. Join the ranks of satisfied homeowners who found the <strong>fastest way to sell</strong> with us, avoiding the complexities of <strong>traditional estate agents</strong> and enjoying a stress-free experience.</p>


        <div class="my-8 flex justify-center items-center">
            <img src="@/assets/sell-house-fast-im1.webp" alt="Happy British couple with a 'SOLD' sign after ASAP House Buyers buying their property." class="rounded-lg shadow-md" />
        </div>
        <h2 class="text-2xl font-semibold mb-4">Understanding Your Selling Options</h2>
        
        <!-- Local Estate Agent -->
        <div class="p-4 mb-6 bg-white rounded-lg shadow-sm">
            <h3 class="font-bold text-lg text-blue-800 mb-2">Local Estate Agent</h3>
            <p class="mb-3">Selling through <strong>traditional estate agents</strong> is common but can be slow, taking up to six months or more. While they can facilitate sales, their timelines and costs might not meet your needs for speed and efficiency.</p>
            <ul class="list-disc pl-5">
                <li>Commission fees of 1-3% of the sale price.</li>
                <li>Additional costs for legal services and property refurbishments.</li>
            </ul>
        </div>

        <!-- We Buy Any Home -->
        <div class="p-4 mb-6 bg-blue-100 rounded-lg shadow-sm">
            <h3 class="font-bold text-lg text-blue-800 mb-2">Save Money with ASAP House Buyers</h3>
            <p class="mb-3">Quick sale companies like <strong>ASAP House Buyers</strong> provide a fast alternative, purchasing your property directly, often within seven days, with no additional charges.</p>
            <ul class="list-disc pl-5">
                <li>Completion in as little as 7 - 21 working days.</li>
                <li><strong>Best possible price based</strong> on market and property value.</li>
                <li>No risk of <strong>chain breaks</strong> or fall-throughs with guaranteed cash sales.</li>
            </ul>
        </div>

        <!-- Online Property Portals -->
        <div class="p-4 mb-6 bg-white rounded-lg shadow-sm">
            <h3 class="font-bold text-lg text-blue-800 mb-2">Online Property Portals</h3>
            <p class="mb-3">This DIY method lets you lead your sale but demands significant time and effort in marketing, handling visits, and negotiations. Costs include listing fees and professional photography.</p>
        </div>

        <!-- Assisted House Sale -->
        <div class="p-4 mb-6 bg-blue-100 rounded-lg shadow-sm">
            <h3 class="font-bold text-lg text-blue-800 mb-2">A Slightly Quicker Approach</h3>
            <p class="mb-3"><strong>An assisted house sale</strong> involves selling your property at a pre-agreed price, often to an investor or company that may refurbish and resell. This option can offer a cash advance but may involve waiting for refurbishment completion.</p>
        </div>

        <!-- Benefits of Quick House Sale Services -->
        <div class="p-4 mb-6 bg-white rounded-lg shadow-sm">
            <h3 class="font-bold text-lg text-green-700 mb-2">The Benefits of Quick House Sale Services</h3>
            <p class="mb-3">With <strong>ASAP House Buyers</strong>, experience the fastest sale possible, with benefits including:</p>
            <ul class="list-disc pl-5">
                <li>Speed of Sale: <strong>Sell your house in as little as 7 - 21 days</strong>.</li>
                <li><strong>No Hidden Fees</strong>: Transparent and upfront pricing.</li>
                <li>Guaranteed Cash Sale: Avoid the uncertainties of the <strong>open market</strong>.</li>
            </ul>
        </div>

        <div class="text-center">
          <button class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded shadow-lg mt-6 text-xl mx-auto block" @click="handleButtonClick">
      Get a Free Cash Offer <i class="fas fa-arrow-right ml-2"></i>
    </button>
        </div>
    </div>
</div>


<!---
<div v-if="$route.path.startsWith('/landerV1/') || $route.path.startsWith('/landerV2/')" class="bg-blue-800 full-width flex justify-center items-center text-white p-4">
  <div class="max-w-3xl p-3">
    <h2 class="text-2xl font-bold mb-8 text-center">Are you searching for a reliable home-buying company?</h2>
    <p class="px-4 mb-4">Tired of browsing through endless lists of companies that buy houses?</p>
    <p class="px-4 mb-4">Seeking a fair price for your home without the stress of navigating the open market? How about a seamless and hassle-free property sale?</p>
    <button class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded shadow-lg mt-6 text-xl mx-auto block" @click="goToTop">
        Get a Free Cash Offer <i class="fas fa-arrow-right ml-2"></i>
    </button>
  </div>
</div> -->




<div v-if="!($route.path.startsWith('/thank-you') || $route.path.startsWith('/landerv2/') || $route.path.startsWith('/articles/'))" class="bg-gray-100 flex items-start justify-center p-8">
  <div class="bg-white p-6 rounded-lg shadow-lg lg:flex lg:items-center w-full max-w-6xl">
    <div class="mt-2 lg:mt-0 lg:w-full">
      <div class="w-full max-w-6xl">
        <h2 class="text-2xl font-bold mb-4 text-center">ASAP House Buyers vs. Estate Agents</h2>
        <p class="text-center mb-4">Opt for <strong>ASAP House Buyers</strong> and enjoy a seamless, stress-free home sale. We manage all aspects of the transaction, including legalities, without third-party involvement and <strong>No Fees</strong>. Experience the advantages of working with us over traditional estate agents.</p>
      </div>
      <div class="overflow-x-auto">
        <table class="w-full border-collapse small-device-table">
        <thead>
          <tr>
            <th class="py-2 font-bold text-left border-b-2 border-gray-200">Sales Process</th>
            <th class="py-2 font-bold text-right border-b-2 border-gray-200">ASAP House Buyers</th>
            <th class="py-2 font-bold text-right border-b-2 border-gray-200">Estate Agents</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="px-2 py-2 text-left border-b border-gray-200">Duration</td>
            <td class="px-2 py-2 text-right bg-green-200 border-b border-gray-200">7 - 21 Days</td>
            <td class="px-2 py-2 text-right border-b border-gray-200">5-7 Months</td>
          </tr>
          <tr>
            <td class="px-2 py-2 text-left border-b border-gray-200">Market Values</td>
            <td class="px-2 py-2 text-right border-b border-gray-200">£100,000</td>
            <td class="px-2 py-2 text-right border-b border-gray-200">£100,000</td>
          </tr>
          <tr>
            <td class="px-2 py-2 text-left border-b border-gray-200">Agreed Sales Price</td>
            <td class="px-2 py-2 text-right border-b border-gray-200">£80,000</td>
            <td class="px-2 py-2 text-right border-b border-gray-200">£90,000</td>
          </tr>
          <tr>
            <td class="px-2 py-2 text-left border-b border-gray-200">After Agent Fees</td>
            <td class="px-2 py-2 text-right border-b border-gray-200">£80,000</td>
            <td class="px-2 py-2 text-right border-b border-gray-200">£88,000</td>
          </tr>
          <tr>
            <td class="px-2 py-2 text-left border-b border-gray-200">After Conveyancing</td>
            <td class="px-2 py-2 text-right border-b border-gray-200">£80,000</td>
            <td class="px-2 py-2 text-right border-b border-gray-200">£86,000</td>
          </tr>
          <tr>
            <td class="px-2 py-2 text-left border-b border-gray-200">After Housing Costs</td>
            <td class="px-2 py-2 text-right border-b border-gray-200">£80,000</td>
            <td class="px-2 py-2 text-right border-b border-gray-200">£82,000</td>
            </tr>
            <tr>
            <td class="px-2 py-2 text-left border-b border-gray-200">After Monthly Bills</td>
            <td class="px-2 py-2 text-right border-b border-gray-200 font-bold">£80,000</td>
            <td class="px-2 py-2 text-right border-b border-gray-200 font-bold">£80,000</td>
            </tr>
            </tbody>
            <tfoot>
                <tr>
                  <td colspan="3" class="px-2 py-2 text-center text-sm italic small-device-table">
                    *We're committed to offering exceptional value and convenience. We typically offer up to 80% of the market value for cash acquisitions and aim to close within 7 - 21 days, prices are dependant on timescales and regions. In rare cases, it may take up to 21 days. Additionally, we can provide a rent-free period of stay, allowing you time you need.
                    <button class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded shadow-lg mt-6 text-xl mx-auto block" @click="handleButtonClick">
      Get a Free Cash Offer <i class="fas fa-arrow-right ml-2"></i>
    </button>
                  </td>
                </tr>
              </tfoot>
          </table>
        </div>
     </div>
  </div>
</div>

<div v-if="!$route.path.startsWith('/articles/') && !$route.path.startsWith('/landerV1/') && !$route.path.startsWith('/landerV2/') && !$route.path.startsWith('/thank-you')" class="bg-gray-200 p-8">
  <div class="bg-white p-6 rounded-lg shadow-lg text-center max-w-6xl mx-auto">
    <h2 class="text-2xl font-bold mb-4">We Can Help Under Any Circumstances</h2>
    <p class="mb-4">Whether you're dealing with <strong>debt</strong>, <strong>facing repossession</strong>, or going through personal changes like a <strong>divorce</strong>, <strong>ASAP House Buyers</strong> can offer a fast sale solution. We understand the unique challenges you might be facing and are here to help you move forward quickly and with confidence.</p>
    <div class="grid grid-cols-1 sm:grid-cols-3 gap-4">
      <div class="flex items-center justify-start">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
        </svg>
        Matrimonial
      </div>
      <div class="flex items-center justify-start">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
        </svg>
        Mortgage Arrears
      </div>
      <div class="flex items-center justify-start">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
        </svg>
        Retirement
      </div>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-4">
      <div class="flex items-center justify-start">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
        </svg>
        Inheritance
      </div>
      <div class="flex items-center justify-start">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
        </svg>
        Facing Repossession
      </div>
      <div class="flex items-center justify-start">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
        </svg>
        And more...
      </div>
    </div>

    <button class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded shadow-lg mt-6 text-xl mx-auto block" @click="handleButtonClick">
      Get a Free Cash Offer <i class="fas fa-arrow-right ml-2"></i>
    </button>
    <p class="mt-4">
    Let us help you move on to the next chapter of your life without the usual stress and delays of selling your property.</p>

  </div>
</div>


<!-- FAQs Section -->
<div id="app" class="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-12" v-if="!$route.path.startsWith('/articles/') && $route.path !== '/thank-you'">
  <div class="text-center">
    <h2 class="text-3xl font-bold text-gray-900">Your Questions Answered about our Cash Property Buying Service</h2>
  </div>
  <div class="mt-8">
    <dl class="space-y-6">
      <div v-for="(item, index) in faqs" :key="index" class="space-y-2">
        <dt class="cursor-pointer px-4 py-2 rounded-md bg-gray-100 hover:bg-gray-200 transition-colors duration-200" @click="toggle(index)" :class="{'text-gray-900': item.isOpen, 'text-gray-700': !item.isOpen}">
          <div class="flex justify-between items-center">
            {{ item.question }}
            <svg :class="{'rotate-180': item.isOpen}" class="inline-block w-4 h-4 transition-transform duration-200" viewBox="0 0 24 24">
              <path d="M19 9l-7 7-7-7" stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </div>
        </dt>
        <!-- Use v-html to render the answer with HTML content -->
        <dd v-show="item.isOpen" class="text-gray-600 px-4 py-2 bg-gray-100" v-html="formatAnswer(item.answer)">
        </dd>
      </div>
      <button class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded shadow-lg mt-6 text-xl mx-auto block" @click="handleButtonClick">
      Get a Free Cash Offer <i class="fas fa-arrow-right ml-2"></i>
    </button>
    </dl>
  </div>
</div>



<div v-if="!$route.path.startsWith('/articles/')" class="bg-gray-200 py-8">
  <div class="max-w-3xl mx-auto">
    <h2 class="text-2xl font-bold mb-4 text-center">We have advertised in:</h2>
    <div class="flex flex-wrap justify-center items-center">
      <div class="mx-4 my-2">
        <img src="@/assets/logo1.png" alt="Logo 1" class="h-12">
      </div>
      <div class="mx-4 my-2">
        <img src="@/assets/logo2.png" alt="Logo 2" class="h-12">
      </div>
      <div class="mx-4 my-2">
        <img src="@/assets/logo3.png" alt="Logo 3" class="h-12">
      </div>
      <div class="mx-4 my-2">
        <img src="@/assets/logo4.png" alt="Logo 4" class="h-12">
      </div>
      <div class="mx-4 my-2">
        <img src="@/assets/logo5.png" alt="Logo 5" class="h-12">
      </div>
      <div class="mx-4 my-2">
        <img src="@/assets/logo6.png" alt="Logo 6" class="h-12">
      </div>
    </div>
  </div>
</div>

<div v-if="!$route.path.startsWith('/articles/') && !$route.path.startsWith('/thank-you')" class="mx-auto p-4 max-w-3xl">
  <ArticleList />
</div>





<footer class="bg-gray-900" aria-labelledby="footer-heading">
  <h2 id="footer-heading" class="sr-only">Footer</h2>
  <div class="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
      <!-- First Column: Logo and Info -->
      <div>
        <img src="@/assets/asap-logo-white.svg" alt="ASAP House Buyers Logo" class="h-12 mb-4">
        <p class="text-sm text-gray-300 mb-4">We adhere to strict codes of conduct as set out by relevant organisations.</p>
        <!-- Certifications aligned vertically -->
        <div class="flex flex-col items-start mb-4">
          <img src="@/assets/cert-logos.png" alt="Approved by organization" class="mb-2 h-8">
        </div>
               <!-- Social Icons -->
               <div class="flex space-x-6">
          <!-- Replace these placeholders with actual SVG icons or <img> tags -->
          <a href="https://www.facebook.com/asaphousebuyersuk" class="text-gray-500 hover:text-gray-400">
            <span class="sr-only">Facebook</span>
            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" />
            </svg>
          </a>
          <a href="https://www.youtube.com/@AsapHouseBuyersUK" class="text-gray-500 hover:text-gray-400">
            <span class="sr-only">YouTube</span>
            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <path fill-rule="evenodd" d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z" clip-rule="evenodd" />
            </svg></a>
          <!-- Additional social icons -->
        </div>
      </div>

      <!-- Second Column: About Us and Our Services -->
      <div>
        <h3 class="text-sm font-semibold text-white mb-2">Company Info</h3>
        <ul role="list" class="space-y-2">
          <li><a href="/about-us" class="text-sm text-gray-300 hover:text-white">Who We Are</a></li>
          <li><a href="/how-we-work" class="text-sm text-gray-300 hover:text-white">How We Work</a></li>
          <li><a href="/where-we-buy" class="text-sm text-gray-300 hover:text-white">Where We Buy</a></li>
          <li><a href="/contact-us" class="text-sm text-gray-300 hover:text-white">Contact Us</a></li>
        </ul>
      </div>

      

      <!-- Third Column: Urgent Links -->
      <div>
        <h3 class="text-sm font-semibold text-white mb-2">Urgent Services</h3>
        <ul role="list" class="space-y-2">
          <li><a href="/services/we-buy-any-home" class="text-sm text-gray-300 hover:text-white">We Buy Any Home</a></li>
          <li><a href="/services/stop-eviction-orders" class="text-sm text-gray-300 hover:text-white">Stop Eviction Orders</a></li>
          <li><a href="/services/stop-property-eviction" class="text-sm text-gray-300 hover:text-white">Stop Property Eviction</a></li>
          <li><a href="/services/stop-repossession-now" class="text-sm text-gray-300 hover:text-white">Stop Repossession Now</a></li>
          <li><a href="/services/sell-your-inherited-house" class="text-sm text-gray-300 hover:text-white">Sell Your Inherited House</a></li>
          <li><a href="/services/sell-your-house-fast-skip-the-wait-of-auctions-with-asap-house-buyers" class="text-sm text-gray-300 hover:text-white">Skip Selling at Auction</a></li>
        </ul>
      </div>

      

      <!-- Fourth Column: Legal -->
      <div>
        <h3 class="text-sm font-semibold text-white mb-2">Legal</h3>
        <ul role="list" class="space-y-2">
          <li><a href="/privacy-policy" class="text-sm text-gray-300 hover:text-white">Privacy Policy</a></li>
          <li><a href="/terms-of-use" class="text-sm text-gray-300 hover:text-white">Terms of Use</a></li>
          <li><a href="/cookies-policy" class="text-sm text-gray-300 hover:text-white">Cookie Policy</a></li>
        </ul>
      </div>
    </div>

    <div class="mt-16 border-t border-gray-700 pt-8">
      <p class="text-xs text-gray-400">&copy; 2024 ASAP House Buyers (UK). All rights reserved. Company Registration No: 15500808.</p>
      <p class="text-xs text-gray-400 mt-2">Mailing address: 124 City Road, London, EC1V 2NX. Registered Office: Ground Floor 6&7 Eastway Business Village, Olivers Place, Preston, Lancashire, United Kingdom, PR2 9WT.</p>
      <p class="text-xs text-gray-400 mt-2">
        Disclaimer: The information provided on this website, including any financial or real estate advice, is for general informational purposes only and should not be considered as professional advice. 
        ASAP House Buyers (UK) are not responsible for any actions taken based on the information provided on this website. Visitors are encouraged to seek professional advice before making any financial or real estate decisions. 
        While we strive to keep the information accurate and up-to-date, we make no guarantees about the completeness, accuracy, or reliability of the information on this site. ASAP House Buyers (UK) will not be held liable for any errors, omissions, or delays in the information provided, or for any losses, injuries, or damages arising from its display or use. All information is provided on an as-is basis.
      </p>
    </div>
  </div>
</footer>





</template>



<script>
import AppHeader from "@/components/Header.vue";
import AddressForm from "./components/AddressForm.vue";
import QuizForm from "./components/QuizForm.vue";
import ContactForm from "./components/ContactForm.vue";
import CountdownTimer from "./components/CountdownTimer.vue";
import ArticleList from './components/ArticleList.vue';


export default {
  components: {
    AppHeader,
    AddressForm,
    QuizForm,
    ContactForm,
    CountdownTimer,
    ArticleList, // Add this line to register the ArticleList component

  },
  data() {
    return {
      addressData: {
        street: "",
        postcode: "",
      },
      quizData: {},
      showQuiz: false,
      showContact: false,
      address: {},
      utmCampaign: '',
      utmSource: '',
      utmMedium: '',
      utmKeyword: '',
      utmDevice: '',
      headerImageUrl: require("@/assets/houses-bg.jpg"),
      faqs: [
      {
      "question": "WHY SHOULD I USE YOUR COMPANY?",
      "answer": "Thank you for considering our property buying service. We understand that selling your property can be a challenging process, and we're here to make it as easy and stress-free as possible.\n\nWhen you choose our company, you can rest assured that you're working with a professional and discreet team that is committed to providing you with a fast and efficient service. Unlike traditional buyers, we don't rely on mortgages or bank loans to fund our purchases, and we have our own cash reserves to make instant decisions and get the money to you in a timeframe that suits you.\n\nWhether you're selling due to financial difficulties, a change in lifestyle, or any other reason, we're here to help. Our team has extensive experience in the property industry and can offer you a fair and competitive price for your property. We pride ourselves on our transparent and straightforward approach and will guide you through every step of the process.\n\nSo why choose our company? Because we're dedicated to providing you with peace of mind, a fast and professional service, and a fair and competitive cash offer for your property. If you have any further questions or would like to request a cash offer, please don't hesitate to contact us.",
      "isOpen": false
    },
    {
      "question": "DO YOU REALLY BUY ANY TYPE OF PROPERTY?",
      "answer": "We buy any property type, flats, houses, detached, semi-detached, bungalows etc., regardless of their condition. We are also interested in land, commercial buildings, oddball properties, ugly properties, and derelict properties. We will look at absolutely everything.",
      "isOpen": false
    },
    {
      "question": "WILL I HAVE TO PAY ANY FEES?",
      "answer": "No, you won't have to pay any fees. Just accept our offer, and that's it.",
      "isOpen": false
    },
    {
      "question": "MY PROPERTY IS ALREADY ON THE MARKET, WILL YOU STILL BUY IT?",
      "answer": "Yes, we can still buy your property; however, you will need to check the terms and conditions of your agency agreement to see if you will need to pay them any withdrawal fees.",
      "isOpen": false
    },
    {
      "question": "I'M FACING REPOSSESSION, CAN YOU HELP?",
      "answer": "Indeed, being cash buyers, we can stop repossession. As we understand it is a stressful time, we can offer what you need, Speed, Certainty, and Simplicity. We also let you move in a timescale that suits you. We generally allow up to 3 months in the property after we have purchased it.",
      "isOpen": false
    },
    {
      "question": "DO YOU SIMPLY EXPLOIT THOSE GRAPPLING WITH DEBT BY BUYING THEIR PROPERTIES?",
      "answer": "Absolutely not. There's a significant misunderstanding surrounding our intentions. The vast majority of our clients are individuals or families who are transitioning - be it relocating for a job, downsizing, or any number of reasons.\n\nWe operate within strict legal bounds which prevent us from leaving anyone in a worse financial situation, such as negative equity. It's crucial to acknowledge that a minimal fraction, less than 5%, of those we assist are facing financial difficulties or debt-related challenges. In these sensitive cases, we exercise the highest degree of care and diligence.\n\nOur approach is centered on providing a service that is not just beneficial but crucially supportive, ensuring we partake in a solution that genuinely aids in their financial recovery and stability. We are here to offer a lifeline, not to capitalize on someone's hardship.",
      "isOpen": false
    },
    {
      "question": "HOW DOES ASAP HOUSE BUYERS GENERATE PROFIT?",
      "answer": "Our business model is structured around purchasing properties at a value below the current market rate, enabling us to facilitate much faster transactions than those typically seen in traditional real estate sales. This approach benefits those who need to sell their property quickly, offering them a streamlined and efficient solution.\n\nAfter acquiring a property, our goal is to sell it at a price that more closely aligns with its market value. Taking on this type of investment involves a significant amount of risk, particularly given the high value associated with real estate and the potential for market fluctuations. It's important to note that not every property we invest in results in a profit. Our expertise lies in carefully assessing each situation, leveraging our understanding of the market to make informed decisions that, while not without their challenges, aim to create a win-win scenario for both the seller and our business.",
      "isOpen": false
    },
    {
      "question": "WHAT SETS YOUR SERVICE APART FROM SITES THAT CLAIM TO PURCHASE PROPERTIES FOR 100% MARKET VALUE?",
      "answer": "Our service is different from sites that claim to purchase properties for 100% market value in a few important ways. Firstly, our core business is focused on buying properties quickly and efficiently, and we cater to those who genuinely require our service. We understand that selling a property can be a stressful and time-consuming process, and we aim to make it as easy and hassle-free as possible.\n\nOn the other hand, other companies that claim to offer 100% market value often use a method such as a assisted sale (ASA), which can take much longer and may involve additional fees. There's also no guarantee that your property will sell, and your property will be listed on the open market which you may not want.\n\nAt ASAP House Buyers, we offer a fair and competitive cash offer for your property, with no hidden costs or fees. We have our own cash reserves, which means that we can make instant decisions and complete the sale quickly and efficiently. Of course, we also recognise that every property is unique, and we're always happy to explore alternative options, but only if this is in your best intrest.\n\nOur goal is to provide you with a tailored service that meets your specific needs and helps you achieve your property selling goals. If you have any further questions or would like to request a cash offer, please don't hesitate to contact us. We're committed to providing you with a transparent and straightforward service that you can trust.",
      "isOpen": false
    },
    {
        "question": "WHAT HAPPENS IF YOU DON'T WANT TO BUY MY PROPERTY DIRECTLY",
        "answer": "It's true that while we strive to make offers on a wide range of properties, there might be occasions where a direct purchase by ASAP House Buyers isn't feasible. However, this doesn't mean you're left without options. We have established relationships with a diverse network of property buyers, each with their unique criteria and interests.<br><br>When we encounter a property that doesn't fit our direct purchasing criteria, we leverage this network to find a suitable buyer for you. This process involves:<br><br><strong>Assessing Your Property's Details:</strong> We review the specifics of your property to understand its potential appeal to our network of buyers.<br><br><strong>Matching with Potential Buyers:</strong> We then match your property with potential buyers from our network who are looking for properties like yours.<br><br><strong>Facilitating the Sale:</strong> We assist in facilitating the sale process between you and the potential buyer, ensuring a smooth and efficient transaction.<br><br>Choosing ASAP House Buyers means you're not just getting our direct purchasing power but also access to a broad network of buyers ready to consider your property. Our goal remains to help you sell your property quickly and with as little hassle as possible, regardless of whether we're the direct buyer or connecting you with someone from our network.",
        "isOpen": false
      },
    {
  "question": "WHEN MIGHT ASAP HOUSE BUYERS NOT BE THE RIGHT CHOICE FOR YOU?",
  "answer": "ASAP House Buyers offers a fast, straightforward cash buying service, ideal for those prioritising speed and convenience over achieving a market-value sale price. Here are a few reasons why we might not be the best fit for every seller:<br><br><strong>Below Market Value Offers:</strong> Our offers consider the as-is condition of your property, meaning you don't need to invest in repairs or upgrades. This convenience and the risk we assume in purchasing properties as-is contribute to our offers being below market value. Remember, we face expenses, taxes, fees, and market uncertainties with every property we buy, which also influences our offer prices.<br><br><strong>Your Goals:</strong> If maximising sale price is your priority and you're willing to wait for the right buyer, a traditional sale through a real estate agent might better serve your needs. Our service is tailored for those who value certainty, speed, and ease over price.<br><br><strong>The Trade-off:</strong> We provide a solution for immediate cash needs and a hassle-free sale, taking on the financial and market risks ourselves. If your situation allows for a longer, potentially more lucrative selling process, exploring traditional selling avenues could be more advantageous for you.<br><br>Our aim is to offer a beneficial alternative for homeowners seeking quick, reliable transactions. If these benefits align with your situation and priorities, we're here to help. If not, we understand that our approach may not be suitable for everyone.",
  "isOpen": false
    }

      ],
    };
  },
  methods: {
    handleButtonClick() {
    // Navigate to the desired route
    this.$router.push('/');


    // Scroll to the top of the page after a short delay
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100); // Adjust the delay as needed
    },
    goToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    onAddressSelected(address) {
      this.addressData = address;
      this.showQuiz = true;
    },
    onQuizComplete(quizResults) {
      this.quizData = quizResults;
      this.showQuiz = false;
      this.showContact = true;
    },
    parseUrlParameters() {
    const urlParams = new URLSearchParams(window.location.search);
    this.utmCampaign = urlParams.get('utm_campaign') || '';
    this.utmSource = urlParams.get('utm_source') || '';
    this.utmMedium = urlParams.get('utm_medium') || '';
    this.utmKeyword = urlParams.get('utm_keyword') || '';
    this.utmDevice = urlParams.get('utm_device') || '';
    this.utmMatch = urlParams.get('utm_matchtype') || '';
    this.adId = urlParams.get('adid') || '';
    this.adSetId = urlParams.get('adsetid') || '';
    this.gclid = urlParams.get('gclid') || '';
    },
    toggle(index) {
      this.faqs[index].isOpen = !this.faqs[index].isOpen;
    },

    formatAnswer(answer) {
      return answer.replace(/\n\n/g, '<br><br>');
    },
  },

  created() {
    this.parseUrlParameters();
    },
};
</script>

<style>
.header-container {
  /* Other styles */
  position: relative; /* Add relative positioning */
}

@media screen and (max-width: 640px) {
  .sm\:max-w-sm {
    max-width: 84%;
  }
}

.guaranteed-offer {
  margin: 20px 0;
  text-align: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.guaranteed-offer h2 {
  font-size: 2.5rem; 
  font-weight: bold; 
  margin-bottom: 15px; 
}

.offer-steps {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 20px 0;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 10px;
  flex-basis: 30%; 
  box-sizing: border-box; 
}

.chat-bubble-icon,
.cash-offer-icon,
.bank-icon {
  font-size: 2rem;
  margin-bottom: 10px;
}

@media (max-width: 600px) {
  .step {
    flex-basis: 100%;
  }
}

.bg-custom-color {
  background-color: blue;
}

@media (max-width: 640px) {
  .small-device-table {
      font-size: 14px;
    }

  .small-device-table td,
  .small-device-table th {
      padding: 4px 8px;
    }
  }

.small-device-table tfoot {
      font-size: 6px;
}

/* Adding hover link styles */
.hover-link {
  transition: color 0.3s, transform 0.3s;
}

.hover-link:hover {
  color: red; /* Changing color on hover */
  transform: scale(1.05); /* Adding a little scaling effect on hover */
}
</style>

